import React from 'react';

function About() {
  return (
    <div  style={{ maxWidth: '800px', margin: '20px auto', padding: '20px',background:'#265c4d',borderRadius:'10px' }}>
    <h1 style={{color:'white'}}>About Me</h1>
    <p>Hi, my name is Philimon Nag and I'm a Mobile App Developer.
    I have 2 years of experience in Mobile App Development, and have worked on projects for clients such as DR.Signet.
    My skills include fullstack App Development using java ,kotlin ,dart and Flutter ,with backend Nodejs ,Firebaseand I'm constantly learning and expanding my knowledge.
    I'm passionate about learning new thing, and I'm always looking for new opportunities to challenge myself and grow.</p>
  </div>
  );
}

export default About;
